import React from "react"
import { Link } from "gatsby"

const TagsBox = ({ props }) => {
  if (!props.tags.edges.length) {
    return null;
  }

  return (
    <div className="tagsBox tags are-medium">
      {props.tags.edges.map(tag => (
        <Link
          to={`/blog/tag/${tag.node.slug}`}
          dangerouslySetInnerHTML={{ __html: tag.node.name }}
          className="tagsBox__item tag is-light"
        />
      ))}
    </div>
  )
}

export default TagsBox
