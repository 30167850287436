import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

const FeaturedImage = ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      tipsAndTricks: file(relativePath: { eq: "tips-and-tricks.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reportsAndAnalysis: file(relativePath: { eq: "reports-and-analysis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      companyUpdates: file(relativePath: { eq: "company-updates.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // No featured image? Bail...
  if (!props.featuredImage) {
    if (props.categories.nodes[0].slug === 'tips-and-tricks') {
      return (
        <div className="post__hero">
          <Img fluid={data.tipsAndTricks.childImageSharp.fluid} />
        </div>
      );
    } else if (props.categories.nodes[0].slug === 'reports-and-analysis') {
      return (
        <div className="post__hero">
          <Img fluid={data.reportsAndAnalysis.childImageSharp.fluid} />
        </div>
      );
    } else if (props.categories.nodes[0].slug === 'company-updates') {
      return (
        <div className="post__hero">
          <Img fluid={data.companyUpdates.childImageSharp.fluid} />
        </div>
      );
    } else {
      return (
        <div className="post__heroholder" />
      );
    }
  }

  // Otherwise display featured image.
  return (
    <div className="post__hero mar-t-6 mar-b-6">
      <Img
        fluid={props.featuredImage.imageFile.childImageSharp.fluid}
        alt={props.altText}
      />
    </div>
  );
};

export default FeaturedImage

export const query = graphql`
  fragment FeaturedImageQuery on WordPress_Post {
    featuredImage {
      altText
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 1344, maxHeight: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
