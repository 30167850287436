import React, { Component } from "react";
import { FormattedMessage } from "gatsby-plugin-intl"

import Facebook from "../images/icons/facebook.svg"
import Twitter from "../images/icons/twitter.svg"
import Linkedin from "../images/icons/linkedin.svg"
import Email from "../images/icons/email.svg"

class Share extends Component {
  shareFacebook () {
    const url = `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(document.title)}&u=${encodeURIComponent(window.location.href)}`
    const params = "height=440,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,width=640"
    window.open(url, 'share_via_facebook', params)
    console.log(window.location.href);
  }

  shareTwitter () {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(document.title)}&url=${encodeURIComponent(window.location.href)}`
    const params = "height=440,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,width=640"
    window.open(url, 'share_via_twitter', params)
  }

  shareLinkedin () {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`
    const params = "height=440,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,width=640"
    window.open(url, 'share_via_linked_in', params)
  }

  shareEmail () {
    const url = `mailto:?body=${encodeURIComponent(window.location.href)}%0a%0a${encodeURIComponent(document.title)}`
    window.open(url)
  }

  render() {
    return (
      <div className="share pad-b-6">
        <div className="share__title has-text-grey">
          <FormattedMessage id="blog.share" />
        </div>
        <button className="share__button share__button--facebook button" onClick={() => this.shareFacebook()} aria-label="Share on Facebook">
          <Facebook aria-hidden="true" />
        </button>
        <button className="share__button share__button--twitter button" onClick={() => this.shareTwitter()} aria-label="Share on Twitter">
          <Twitter aria-hidden="true" />
        </button>
        <button className="share__button share__button--linkedin button" onClick={() => this.shareLinkedin()} aria-label="Share on Linkedin">
          <Linkedin aria-hidden="true" />
        </button>
        <button className="share__button share__button--email button is-light" onClick={() => this.shareEmail()} aria-label="Share via email">
          <Email aria-hidden="true" />
        </button>
      </div>
    )
  }
}

export default Share
