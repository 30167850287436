import React from "react"
import { Link } from "gatsby"

const Featured = ({ props }) => {
  if (!props.relatedDate1) {
    return null;
  }

  return (
    <div className="featured mar-b-6">
      <div className="container">
        <div className="featured__header">
          Artigos relacionados
        </div>
        
        <div className="columns">
          <Link to={`/blog/${props.relatedSlug1}`} className="column">
            <div className="featured__card card is-hoverable">
              {props.relatedImage1 &&
                <img src={props.relatedImage1} className="featured__image" alt="{props.relatedTitle1}" />
              }
              <div className="featured__inner">
                <div className="featured__title" dangerouslySetInnerHTML={{ __html: props.relatedTitle1 }} />
                <div className="featured__date has-text-grey">
                  { new Date(props.relatedDate1).toLocaleString('pt-BR', { dateStyle: 'long' }) }
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/blog/${props.relatedSlug2}`} className="column">
            <div className="featured__card card is-hoverable">
              {props.relatedImage2 &&
                <img src={props.relatedImage2} className="featured__image" alt="{props.relatedTitle2}" />
              }
              <div className="featured__inner">
                <div className="featured__title" dangerouslySetInnerHTML={{ __html: props.relatedTitle2 }} />
                <div className="featured__date has-text-grey">
                  { new Date(props.relatedDate2).toLocaleString('pt-BR', { dateStyle: 'long' }) }
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/blog/${props.relatedSlug3}`} className="column">
            <div className="featured__card card is-hoverable">
              {props.relatedImage3 &&
                <img src={props.relatedImage3} className="featured__image" alt="{props.relatedTitle3}" />
              }
              <div className="featured__inner">
                <div className="featured__title" dangerouslySetInnerHTML={{ __html: props.relatedTitle3 }} />
                <div className="featured__date has-text-grey">
                  { new Date(props.relatedDate3).toLocaleString('pt-BR', { dateStyle: 'long' }) }
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Featured
