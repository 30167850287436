import React from "react"
import { Disqus } from "gatsby-plugin-disqus"
import { FormattedMessage } from "gatsby-plugin-intl"

const Comments = ({ props }) => {
  let disqusConfig = {
    //url: `${config.siteUrl+location.pathname}`,
    identifier: props.postId,
    title: props.title,
  }

  return (
    <div className="container">
      <div className="columns">
        <div className="column has-text-centered">
          &nbsp;
        </div>
        <div className="column is-two-thirds">
          <div className="columns is-centered">
            <div className="column post__comments mar-b-6">
              <div className="featured__header">
                <FormattedMessage id="blog.comments" />
              </div>
              <Disqus config={disqusConfig} />
            </div>
          </div>
        </div>
        <div className="column has-text-centered">
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default Comments
