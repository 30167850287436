import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image";

import Twitter from "../images/icons/twitter.svg"
import Linkedin from "../images/icons/linkedin.svg"

const AuthorBox = ({ props }) => (
  <div className="authorBox pad-b-6">
    <div className="columns">
      {props.author.UserCustom.avatar && (
        <div className="column is-narrow">
          <Link to={`/blog/author/${props.author.slug}`}>
            <Img
              fixed={props.author.UserCustom.avatar.imageFile.childImageSharp.fixed}
              alt={props.author.name}
              className="authorBox__avatar"
            />
          </Link>
        </div>
      )}
      <div className="column">
        <div className="authorBox__header has-text-grey">
          Written by
        </div>
        <Link
          to={`/blog/author/${props.author.slug}`}
          className="authorBox__name"
          dangerouslySetInnerHTML={{ __html: props.author.name }}
        />
        <div className="authorBox__title">
          {props.author.UserCustom.jobTitle}
        </div>
        <div className="authorBox__description has-text-grey">
          {props.author.description}
        </div>
        {props.author.UserCustom.twitter &&
          <a href={props.author.UserCustom.twitter} className="share__button share__button--twitter share__button--small button" target="_blank" rel="noopener noreferrer" title="Twitter">
            <Twitter />
          </a>
        }
        {props.author.UserCustom.linkedin &&
          <a href={props.author.UserCustom.linkedin} className="share__button share__button--linkedin share__button--small button" target="_blank" rel="noopener noreferrer" title="Linkedin">
            <Linkedin />
          </a>
        }
      </div>
    </div>
  </div>
)

export default AuthorBox
