import React, { Component } from "react";
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import NiceDate from "../components/niceDate"
import FeaturedImage from "../components/featuredImage"
import Share from "../components/share"
import AuthorBox from "../components/authorBox"
import TagsBox from "../components/tagsBox"
import Featured from "../components/blocks/featured"
import Comments from "../components/comments"
import Demo from "../components/blocks/demo"

import shareThis from "share-this"
import * as facebookSharer from "share-this/dist/sharers/facebook"
import * as twitterSharer from "share-this/dist/sharers/twitter"
import * as linkedinSharer from "share-this/dist/sharers/linked-in"
import * as emailSharer from "share-this/dist/sharers/email"

class PostTemplate extends Component {
  componentDidMount = () => {
    const selectionShare = shareThis({
      selector: '.post__content',
      sharers: [ facebookSharer, twitterSharer, linkedinSharer, emailSharer ]
    })
    
    selectionShare.init()
  }

  render() {
    const post = this.props.data.wordpress.post

    return (
      <Layout>
        <SEO title={post.title} author={post.author.name} image={post.featuredImage.sourceUrl} />
        <div className="post pad-t-6" id={post.databaseId}>
          <header className="container">
            <h1 className="post__title has-text-centered mar-b-6" dangerouslySetInnerHTML={{ __html: post.title }} />
            <div className="columns is-centered">
              <div className="column is-three-quarters is-full-desktop has-text-centered">
                <Link to={`/blog/author/${post.author.slug}`} className="post__meta post__meta--author">
                  {post.author.UserCustom.avatar && 
                    <Img
                      fixed={post.author.UserCustom.avatar.imageFile.childImageSharp.fixed}
                      alt={post.author.name}
                      className="post__meta__avatar"
                    />
                  }
                  {post.author.name}
                </Link>
                <span className="post__meta has-text-grey">
                  <NiceDate date={post.date} />
                </span>
                <span className="post__meta has-text-grey">
                  <Link
                    to={`/blog/category/${post.categories.nodes[0].slug}`}
                    dangerouslySetInnerHTML={{ __html: post.categories.nodes[0].name }}
                  />
                </span>
              </div>
            </div>
          </header>

          <FeaturedImage props={post} />

          <div className="container">
            <div className="columns">
              <div className="column has-text-centered">
                <Share />
              </div>
              <div className="column is-two-thirds">
                <div className="columns is-centered">
                  <div className="post__content content column" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
                <div className="columns is-centered">
                  <div className="column authorBoxWrapper">
                    <TagsBox props={post} />
                    <AuthorBox props={post} />
                  </div>
                </div>
              </div>
              <div className="column has-text-centered">
                &nbsp;
              </div>
            </div>
          </div>
        </div>

        <Featured props={post} />
        <Comments props={post} />
        <Demo props={post} />
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query($id: ID!) {
    wordpress {
      post(id: $id) {
        databaseId
        title(format: RENDERED)
        content
        date
        relatedTitle1
        relatedTitle2
        relatedTitle3
        relatedDate1
        relatedDate2
        relatedDate3
        relatedSlug1
        relatedSlug2
        relatedSlug3
        relatedAuthor1
        relatedAuthor2
        relatedAuthor3
        relatedImage1
        relatedImage2
        relatedImage3
        author {
          name
          slug
          description
          UserCustom {
            jobTitle
            linkedin
            twitter
            avatar {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed(width: 100, height: 100, quality: 80) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        ...FeaturedImageQuery
        categories {
          nodes {
            name
            slug
          }
        }
        tags(last: 20) {
          edges {
            node {
              name
              slug
            }
          }
        }
      }
    }
  }
`;
